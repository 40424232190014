import React, { useEffect, useState } from 'react'
import config from '../../config'
import { post } from '../../utils/http'
import { SessionBrief } from '../../types/SessionBrief'
import { Link, useParams } from 'react-router-dom'
import SpinnerIcon from '../Icons/SpinnerIcon'
import Filter from '../SessionFilter/SessionFilter'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import { getFilterPayload, useFilterContext } from '../../contexts/filter-context'
import SessionListItem from '../SessionListItem/SessionListItem'

export default function SessionsList() {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const { filterState } = useFilterContext()
  const [sessions, setSessions] = useState<Array<SessionBrief>>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchSessions = async () => {
      if (!filterState.filter?.storeId) {
        return
      }
      else if (filterState.filter.from > filterState.filter.to) {
        return
      }
      
      setLoading(true)
      setError(false)
      setSessions([])
      try {
        const filterPayload = getFilterPayload(filterState.filter)
        const result = await post<typeof filterPayload, Array<SessionBrief>>(`${config.STORE_API_BASE_URL}/v2/session/`, filterPayload)
        const sortedBydate = result.sort((a, b) => {
          return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime()
        })
        setLoading(false)
        setSessions(sortedBydate)
      } catch (error) {
        setLoading(false)
        setError(true)
      }
    }
    fetchSessions()
  }, [filterState.filter])

  const onModalClose = () => {
    navigate('/sessions')
  }

  return (
    <div className='list-content__container'>
      <div className="list list-content__left">
        {!loading && !error && sessions.length !== 0 && <div className='list-content__count mb-1'>Visar {sessions.length} kundvarv</div>}
        {loading &&
          <div className='grid place-content-center'>
            <SpinnerIcon />
          </div>
        }
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        {sessions.map((session, i) => {
          return (
            <div className='list-item' key={i}>
              <Link to={`/sessions/${session.id}`}>
                <SessionListItem session={session}></SessionListItem>
              </Link>
              {sessionId && sessionId === session.id &&
                <Modal title='Kundvarv' show={!!sessionId} onClose={onModalClose}>
                  <SessionInfo />
                </Modal>
              }
            </div>
          )
        })}
        {!loading && !error && sessions.length === 0 && <div className='list-content__placeholder'>Inget resultat hittades för din sökning.</div>}
      </div> 
      <div className='list-content__right'>
        <Filter />
      </div>
    </div>
  )
}

