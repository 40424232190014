import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowIconMinimal from '../Icons/ArrowIconMinimal'

export interface NavigateBackProps {
  backUrl?: string
}

const NavigateBack = ({ backUrl }: NavigateBackProps) => {
  const navigate = useNavigate()
  const goBackOne = () => {
    navigate(-1)
  }

  return (
    <div className='icon-text'>
      {backUrl ?
        <Link to={backUrl}><ArrowIconMinimal direction='left' color='var(--color-dark-gray)'/><span className='ml-2'>Tillbaka</span></Link>
        : 
        <button onClick={goBackOne}><ArrowIconMinimal direction='left' color='var(--color-dark-gray)'/><span className='ml-2'>Tillbaka</span></button>
      } 
    </div>
  )
}

export default NavigateBack