import React from 'react'
import { Link } from 'react-router-dom'
import './OverviewStatisticsItem.scss'

export interface IOverviewStatisticsItem {
  linkTo: string
  count: number
  text: string
  className?: string
  markerColor?: string
  onClick?: () => void
}

export function OverviewStatisticsItem({ linkTo, count, text, className, markerColor, onClick } : IOverviewStatisticsItem) {
  return (
    <div className={`overview-statistics-item${className ? ' ' + className : ''}`}>
      <Link to={linkTo} className='flex h-full items-center justify-center relative' onClick={onClick}>
        <div className='flex flex-col items-center'>
          <div className='overview-statistics-item-count'>{count}</div>
          <div className='overview-statistics-item-text'>{text}</div>
        </div>
        {markerColor && count > 0 && <div className='overview-statistics-item-marker' style={{ 'background': markerColor }}></div>}
      </Link>
    </div>
  )
}
