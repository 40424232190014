import { ReactElement, RefObject } from "react"

export type ElementWithRef = ReactElement & { props: { ref?: RefObject<HTMLElement> } }

const calculateBoundingBoxes = (children: Array<ElementWithRef>) => {
  const boundingBoxes: { [key: string]: DOMRect | undefined } = {}

  children.forEach(child => {
    if (!child || !child.key || !child.props.ref) {
      throw new Error()
    }

    const domNode = child.props.ref?.current
    const nodeBoundingBox = domNode?.getBoundingClientRect()

    boundingBoxes[child.key] = nodeBoundingBox
  })

  return boundingBoxes
}

export default calculateBoundingBoxes