import { useEffect, useRef } from "react"
import { ElementWithRef } from "../../utils/calculateBoundingBoxes"

const usePrevious = (value: Array<ElementWithRef>) => {
  const prevChildrenRef = useRef<Array<ElementWithRef>>([])

  useEffect(() => {
    prevChildrenRef.current = value
  }, [value])

  return prevChildrenRef.current
}

export default usePrevious