import React, { useMemo } from 'react'
import { OrderItem } from '../../types/OrderItem'
import { SessionBrief } from '../../types/SessionBrief'
import "./TopOrderItems.scss"

export interface TopOrderItemsProps {
  sessions: Array<SessionBrief>
}

const TopOrderItems = ({ sessions } : TopOrderItemsProps) => {
  const calculateTopOrdersItems = (sessions:  Array<SessionBrief>, count: number) => {
    const orderItems = sessions.flatMap((session) => {
      if (session.hasCompletedPayment) {
        return session.orderItems
      }
      return null
    }).filter((orderItem) => Boolean(orderItem))  as Array<OrderItem>

    const orderItemsWithCount = orderItems.map((orderItem) => {
      const count = orderItems.reduce((acc, curr) => {
        return acc + (curr.itemId === orderItem.itemId ? 1 : 0)
      }, 0)
      return { ...orderItem, count }
    })
  
    return [...new Set(orderItemsWithCount.map(o => o.itemId))]
      .map(id => {
        return orderItemsWithCount.reduce((acc, curr) => curr.itemId === id ? curr : acc)
      })
      .sort((a, b) => {
        return b.count - a.count
      })
      .slice(0, count)
  }
  const topOrderItems = useMemo(() => calculateTopOrdersItems(sessions, 6), [sessions])

  return (
    <div className='top-order-items'>
      <div className='overview-statistics-subtitle select-none mb-3'>Toppsäljare</div>
      <div className='top-order-items__list'>
        {topOrderItems.length > 0 ?
          topOrderItems.map((item, i) => 
            <div key={i} className="top-order-items__list-item">
              <div className='grid place-content-center pl-6'>
                <img 
                  className="top-order-items__img" 
                  src={`https://assets.icanet.se/image/upload/w_64,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${item.ean}.webp`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src="https://assets.icanet.se/image/upload/w_64,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp"
                  }}
                />
              </div>
              <div className='top-order-items__list-item-text grid content-center overflow-hidden p-3'>
                {item.displayName}
              </div>
              <div  className='top-order-items__count grid content-center pl-3 pr-6'>
                {item.count} st
              </div>
            </div>
          ) :
          <div className='top-order-items__placeholder'>
            <div className='flex items-center justify-center'>Inga produkter sålda idag.</div>
          </div>}
      </div>
    </div>
  )
}

export default TopOrderItems