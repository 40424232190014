import { SessionBrief } from "../types/SessionBrief"
import { SessionDetails } from "../types/SessionDetails"
import displayPrice from "./displayPrice"

export function getSessionSummary(session: SessionBrief | SessionDetails): string {
  if (session.isVending) {
    return 'Varupåfyllning'
  }

  let summary: string = `${session.itemCount} varor`

  if (session.orderNumber) {
    summary += `, ${displayPrice(session.totalPrice)} kr`
  }

  return summary
}