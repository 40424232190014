import React, { useEffect, useLayoutEffect, useState } from 'react'
import calculateBoundingBoxes, { ElementWithRef } from '../../utils/calculateBoundingBoxes'
import usePrevious from './usePrevious'

export default function AnimatedList({ children }: { children: Array<ElementWithRef> }){
  const previousChildren = usePrevious(children)
  const [box, setBox] = useState<{ [key: string]: DOMRect | undefined }>({})
  const [previousBox, setPreviousBox] = useState<{ [key: string]: DOMRect | undefined }>({})
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useLayoutEffect(() => {
    if (children) {
      setBox(calculateBoundingBoxes(children))
    }
  }, [children, windowSize])

  useLayoutEffect(() => {
    if (previousChildren) {
      setPreviousBox(calculateBoundingBoxes(previousChildren))
    }
  }, [previousChildren, windowSize])

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const hasPrevBoundingBox = Object.keys(previousBox).length
    if (!hasPrevBoundingBox) return

    children.forEach(child => {
      if (!child.key) return

      const ref = child?.props.ref?.current
      const previous = previousBox[child.key]
      const current = box[child.key]

      if (previous && current) {
        const shiftX = previous.left - current.left
        const shiftY = previous.top - current.top

        if (ref && (shiftX || shiftY)) {
          requestAnimationFrame(() => {
            ref.style.transform = `translate(${shiftX}px, ${shiftY}px)`
            ref.style.transition = 'transform 0s'
    
            requestAnimationFrame(() => {
              ref.style.transform = ''
              ref.style.transition = 'transform 500ms'
            })
          })
        }
      }
    })
  }, [box, previousBox, children])

  return(
    <>
      {children}
    </>
  )
}