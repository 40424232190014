import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { SessionDetails } from '../../types/SessionDetails'
import { dateStringToDisplayDate, differenceInMinutes, getSessionDateTimeSpan } from '../../utils/dateUtils'
import { get } from '../../utils/http'
import SpinnerIcon from '../Icons/SpinnerIcon'
import "./SessionInfo.scss"
import AcrFlag from '../AcrFlag/AcrFlag'
import SessionTags from '../SessionTags/SessionTags'
import { useDeviceContext } from '../../contexts/device-context'
import { getSessionSummary } from '../../utils/sessionUtils'


export default function SessionInfo() {
  const { storeState } = useStoreContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [session, setSession] = useState<SessionDetails | null>(null)
  const { sessionId } = useParams()
  const { deviceState } = useDeviceContext()

  const fetchSession = async () => {
    try {
      const result = await get<SessionDetails>(`${config.STORE_API_BASE_URL}/v1/monitor/session/${storeState.selectedStore?.id}/${sessionId}`)
      const sortedEvents = result.events.sort((eventA, eventB) => {
        return new Date(eventA.createdAt).getTime() - new Date(eventB.createdAt).getTime()
      })
      setLoading(false)
      setSession({ ...result, events: sortedEvents })
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const eventTypeTranslation =  {
    AbortConfirmed: 'Kundvarv avslutat',
    AbortPressedWithItemsInCart: 'Kund vill avbryta - Artiklar i varukorg',
    AbortPressedWithoutItemsInCart: 'Kund vill avbryta',
    AddToCart : 'Lägg till i varukorg',
    AgeAccessDenied: 'Identifiering (BankID)',
    CreateCart: 'Skapa varukorg',
    DoorAccessDenied: 'Öppna dörr',
    EndVendingMode: 'Avsluta påfyllning av varuautomat',
    EnterDoor : 'Öppna dörr',
    ExitDoor : 'Dörr ut',
    InitPayment : 'Start betalning',
    NotStammisAccessDenied: 'Identifiering (BankID)',
    PaymentComplete : 'Betalning genomfört',
    Purchase : 'Betalning pågår',
    RemoveFromCart : 'Ta bort ifrån varukorg',
    StartVendingMode: 'Starta påfyllning av varuautomat',
    Timeout: 'Timeout',
  }

  useEffect(() => {
    if(sessionId && storeState.selectedStore?.id) {
      fetchSession()
    }
  }, [sessionId, storeState.selectedStore?.id])

  return (
    <div className="session-info">
      {session && (
        <>
          {deviceState.isMobile
            ? <div className='mt-6 mb-2'>
              {['/live-feed', '/sessions'].some(path => location.pathname.includes(path)) && session.customerId &&
                <Link to={`/customers/${session.customerId}/${session.acr}`} state={{ previousUrl: `${location.pathname}${location.search}` }} className='session-info__customer ml-auto'>Visa kund</Link>
              }
              <div className='session-info__identifier my-1'>{session.id}</div>
              {session.acr && <div><AcrFlag acr={session.acr} width={18}/></div>}
            </div>
            : <div className='mt-6 flex items-center'>
              <div className='session-info__identifier'>{session.id}</div>
              {session.acr && <span className='ml-0 mr-2 md:ml-2 md:mr-0'><AcrFlag acr={session.acr} width={18}/></span>}
              {['/live-feed', '/sessions'].some(path => location.pathname.includes(path)) && session.customerId &&
                <Link to={`/customers/${session.customerId}/${session.acr}`} state={{ previousUrl: `${location.pathname}${location.search}` }} className='session-info__customer ml-auto'>Visa kund</Link>
              }
            </div>}
          <div className='session-info__identifier font-bold mt-1'>{getSessionDateTimeSpan(session.firstActivity, session.latestActivity)} ({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</div>
          <div className='session-info__item-summary mt-1'>{getSessionSummary(session)}</div>
          {session.paymentInternalDisplayName && <div className='session-info__payment_method mt-1'>{session.paymentInternalDisplayName}</div>}
          <div className='mt-2'>
            <SessionTags accessDeniedReason={session.events.find(e => e.eventType === 'DoorAccessDenied' 
              || e.eventType === 'AgeAccessDenied' 
              || e.eventType === 'NotStammisAccessDenied')?.eventType || undefined}
            hasCustomerWeighedItems={session.hasCustomerWeighedItems}
            hasMultipleCompletedPayments={session.hasMultipleCompletedPayments}
            hasRemovedItems={session.hasRemovedItems}
            isMissingPayment={session.isMissingPayment}>
            </SessionTags>
          </div>
          <div className='text-lg font-bold mt-5'>Händelser</div> 
          <hr className='my-3'/>
          
          {session.events.map((event, i) => 
            <div key={i}>
              <div className='flex'>
                <div className='session-info__event'>
                  <span>{eventTypeTranslation[event.eventType]}</span>
                  {(event.eventType === 'DoorAccessDenied') &&
                    <div className='truncate font-bold'>
                      Spärrad användare
                    </div>
                  }
                  {(event.eventType === 'AgeAccessDenied') &&
                    <div className='truncate font-bold'>
                      Spärrad användare p.g.a. ålderskrav
                    </div>
                  }
                  {(event.eventType === 'NotStammisAccessDenied') &&
                    <div className='truncate font-bold'>
                      Spärrad användare p.g.a. icke-stammis
                    </div>
                  }
                  {event.itemName && 
                    <div>
                      <span className='font-bold' title={event.itemName}>{event.itemName} </span>
                      {event.itemWeightInGrams && <span>(Vikt: {event.itemWeightInGrams}g)</span>}
                    </div>
                  }
                  {event.eventType === 'RemoveFromCart' && 
                  <div className='mt-1'>
                    <div className='session-tags__tag inline-block'>Borttagning av vara</div>
                  </div>
                  }
                </div>
                <span className='session-info__event-timestamp ml-auto pl-3 self-center'>{dateStringToDisplayDate(event.createdAt).substring(11, 20)}</span>
              </div>
              <hr className='my-3'/>
            </div>)}
        </>
      )}
      {loading &&
      <div className='grid place-content-center h-72'>
        <SpinnerIcon />
      </div>
      }
      {!loading && error && 
      <div className='grid place-content-center h-72'>
        <p className='error text-center'>Någonting gick fel.</p>
      </div>
      }
    
    </div>
  )
}
