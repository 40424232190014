import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { SessionBrief } from '../../types/SessionBrief'
import { get, post } from '../../utils/http'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import SpinnerIcon from '../Icons/SpinnerIcon'
import NavigateBack from '../NavigateBack/NavigateBack'
import UserIcon from '../Icons/UserIcon'
import '../../styles/shared/list.scss'
import './CustomerInfo.scss'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import SessionListItem from '../SessionListItem/SessionListItem'
import AcrFlag from '../AcrFlag/AcrFlag'
import InputText from '../InputText/InputText'
import PlusIcon from '../Icons/PlusIcon'
import SidebarModal from '../SidebarModal/SidebarModal'
import CloseIcon from '../Icons/CloseIcon'
import WarningIcon from '../Icons/WarningIcon'
import { getLocalizedDateTimeString } from '../../utils/dateUtils'

interface Customer {
  id: string
  acr: string
  doorBlock: DoorBlock | null
  sessions: Array<SessionBrief>
}

interface DoorBlock {  
  id: string  
  customerId: string
  acr: string
  createdById: string
  createdByEmail: string
  createdByStoreId: string
  createdByStoreName: string
  createdByMainStoreName: string
  createdAt: string
  updatedAt: string
  customerHasNonpayment: boolean
  customerHasMismanagedWeighedItems: boolean
  customerHasLetOthersIntoStore: boolean
  customerHasVandalized: boolean
}

interface UpdateCustomerBanRequest {
  customerHasNonpayment: boolean
  customerHasMismanagedWeighedItems: boolean
  customerHasLetOthersIntoStore: boolean
  customerHasVandalized: boolean
}

export default function CustomerInfo() {
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { id, acr, sessionId } = useParams()
  const { storeState } = useStoreContext()
  const [banError, setBanError] = useState(false)
  const [displayBanModal, setDisplayBanModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const backUrl = useRef(location?.state?.previousUrl ?? null)
  const [isSavingBanReasons, setIsSavingBanReasons] = useState(false)
  const banReasonButtonDefaultMsg = "Ingen vald"
  const [banReasonButtonMsg, setBanReasonButtonMsg] = useState<string>(banReasonButtonDefaultMsg)
  const banReasonTexts = {
    customerHasNonpayment: "Utebliven betalning",
    customerHasMismanagedWeighedItems: "Felaktig hantering viktvaror",
    customerHasLetOthersIntoStore: "Släppt in obehöriga",
    customerHasVandalized: "Vandalisering",
  }

  useEffect(() => {
    if (storeState.selectedStore?.id) {
      fetchCustomerInfo()
    }
  }, [storeState.selectedStore?.id])

  useEffect(() => {
    if (!customer?.doorBlock) {
      setBanReasonButtonMsg(banReasonButtonDefaultMsg)
      return
    }

    const activeBanReasons = Object.entries(banReasonTexts)
      .filter(([key]) => customer.doorBlock?.[key as keyof DoorBlock])
      .map(([, value]) => value)

    if (activeBanReasons.length == 0) {
      setBanReasonButtonMsg(banReasonButtonDefaultMsg)      
    }
    else if (activeBanReasons.length == 1) {
      setBanReasonButtonMsg(activeBanReasons[0])
    }
    else {
      setBanReasonButtonMsg(`${activeBanReasons[0]}, +${activeBanReasons.length - 1}`)
    }
  }, [customer?.doorBlock])

  const fetchCustomerInfo = async () => {
    setLoading(true)
    setCustomer(null)
    setError(false)
    try {
      const customer = await get<Customer>(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${storeState.selectedStore?.id}/${id}/${acr}`)
      customer.sessions = customer.sessions.sort((a, b) => {
        return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime()
      })
      setCustomer(customer)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }

  const onModalClose = () => {
    navigate(`/customers/${id}/${acr}`)
  }

  const toggleBanCustomer = async (ban: boolean) => {
    if (customer === null) {
      return
    }
  
    setBanError(false)
    const newCustomer: Customer = { ...customer }
    
    try {
      if (ban) {
        const body = { CreatedByStoreId: storeState.selectedStore?.id }
        const newDoorBlock: DoorBlock = await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/ban`, body)        
        newCustomer.doorBlock = newDoorBlock
        setCustomer(newCustomer)
      }
      else { // Unban
        await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/unban`,  null)
        newCustomer.doorBlock = null
        setCustomer(newCustomer)
        setDisplayBanModal(false)
      }
    } catch (error) {
      setBanError(true)
    }
  }

  const handleBanReasonChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const body: UpdateCustomerBanRequest = {
      customerHasLetOthersIntoStore: customer?.doorBlock?.customerHasLetOthersIntoStore ?? false,
      customerHasMismanagedWeighedItems: customer?.doorBlock?.customerHasMismanagedWeighedItems ?? false,
      customerHasNonpayment: customer?.doorBlock?.customerHasNonpayment ?? false,
      customerHasVandalized: customer?.doorBlock?.customerHasVandalized ?? false,
    }

    body[event.target.name as keyof UpdateCustomerBanRequest] = event.target.checked // Currently all ban reason inputs are checkboxes
    await updateCustomerBan(body)
  }

  const handleBanReasonsReset = async () => {
    const body: UpdateCustomerBanRequest = {
      customerHasNonpayment: false,
      customerHasMismanagedWeighedItems: false,
      customerHasLetOthersIntoStore: false,
      customerHasVandalized: false,
    } as UpdateCustomerBanRequest
    await updateCustomerBan(body)
  }

  const updateCustomerBan = async(body: UpdateCustomerBanRequest) => {
    if (customer === null) {
      return
    }

    setIsSavingBanReasons(true)
    try {
      const newDoorBlock: DoorBlock = await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/ban/update`, body)  
      const newCustomer: Customer = { ...customer }      
      newCustomer.doorBlock = newDoorBlock
      setCustomer(newCustomer)
    }
    finally {
      setIsSavingBanReasons(false)
    }
  }

  return (
    <>
      <div className='mb-5'>
        <NavigateBack backUrl={backUrl.current} />
      </div>
      <div className='customer-info list-content__container'>
        <div className='list list-content__left'>
          {loading &&
          <div className='grid place-content-center'>
            <SpinnerIcon />
          </div>
          }
          {customer && customer.sessions.map((session) => 
            <div className='list-item' key={session.id}>
              <Link to={`/customers/${id}/${acr}/${session.id}`}>
                <SessionListItem session={session}></SessionListItem>
              </Link>
            </div>
          )}
          {!loading && !error && customer && customer.sessions.length === 0 && <div className='list-content__placeholder'>Ingen kundhistorik kunde hittas för angiven butik.</div>}
          {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        </div>
        {customer && 
        <div className='list-content__right'>
          <div className='customer-info__list-item list p-4'>
            <div className='flex items-center'>
              <UserIcon size='24px' />
              <div className='pl-2'>
                <div className='customer-info__text-top customer-info__id mb-1 flex items-center'>Kund-id: {customer.id}{acr && <div className='ml-3'><AcrFlag acr={acr} width={18}/></div>}</div>
                <div className='customer-info__text-bottom mt-1'>Antal kundvarv: {customer.sessions.length}</div>
              </div>
            </div>
            <hr className='my-4'></hr>
            <div className='flex items-center'>
              <label className='customer-info__ban-text my-0 ml-0 mr-2'>Spärra kund</label>
              <div className='ml-auto'>
                <InputCheckbox id={'ban-checkbox'} checked={!!customer.doorBlock} onCheckChanged={(e) => toggleBanCustomer(e.target.checked)} />
              </div>
            </div>
            {customer.doorBlock && <>
              <hr className='my-4'></hr>
              <div className='flex'>
                <div>
                  <div className='customer-info__ban-text'>Spärrad {getLocalizedDateTimeString(customer.doorBlock.createdAt, false)}</div>
                  <div className='customer-info__ban-text'>{customer.doorBlock.createdByStoreName}</div>
                  {customer.doorBlock.createdByMainStoreName && <div className='customer-info__ban-subtext'>{customer.doorBlock.createdByMainStoreName}</div>}
                </div>
                <div className='ml-auto content-center'>
                  <WarningIcon size='24px' />
                </div>
              </div>
              <hr className='my-4'></hr>
              <label htmlFor='ban-modal-button' className='customer-info__ban-text-color select-none'>Anledning till spärrning</label>
              <InputText
                value={banReasonButtonMsg}
                icon={<PlusIcon size='24px' />} 
                onChange={() => null}
                onClick={() => setDisplayBanModal(!displayBanModal)}
                className='cursor-pointer outline-none'
                id='ban-modal-button'
                readonly />
              <SidebarModal show={displayBanModal}>
                <div className='border-bottom flex py-3 px-5'>
                  <div className='filter-title select-none'>Spärra kund</div>
                  <div className='close-button ml-auto' onClick={() => setDisplayBanModal(false)}>
                    <CloseIcon size='20px' />
                  </div>
                </div>
                <div className='px-5 mb-3'>
                  <div className='filter-subtitle mt-6'>Anledning till spärrning</div>
                  <hr className='mt-4'/>
                  <InputCheckbox label={banReasonTexts['customerHasNonpayment']} id='customerHasNonpayment' name='customerHasNonpayment' checked={customer.doorBlock.customerHasNonpayment} onCheckChanged={handleBanReasonChange} className='py-4' disabled={isSavingBanReasons} />
                  <hr/>
                  <InputCheckbox label={banReasonTexts['customerHasMismanagedWeighedItems']} id='customerHasMismanagedWeighedItems' name='customerHasMismanagedWeighedItems' checked={customer.doorBlock.customerHasMismanagedWeighedItems} onCheckChanged={handleBanReasonChange} className='py-4' disabled={isSavingBanReasons} />
                  <hr/>
                  <InputCheckbox label={banReasonTexts['customerHasLetOthersIntoStore']} id='customerHasLetOthersIntoStore' name='customerHasLetOthersIntoStore' checked={customer.doorBlock.customerHasLetOthersIntoStore} onCheckChanged={handleBanReasonChange} className='py-4' disabled={isSavingBanReasons} />
                  <hr/>
                  <InputCheckbox label={banReasonTexts['customerHasVandalized']} id='customerHasVandalized' name='customerHasVandalized' checked={customer.doorBlock.customerHasVandalized} onCheckChanged={handleBanReasonChange} className='py-4' disabled={isSavingBanReasons} />
                  <hr/>
                </div>
                <div className='filter-footer mt-auto border-top sticky bottom-0'>
                  <div className='flex p-4 w-full'>
                    <button type="button" className='filter-reset-button button button-secondary grow mr-1 xl:mr-0' onClick={handleBanReasonsReset}>Rensa</button>
                    <button type="button" className='filter-reset-button button grow ml-1' onClick={() => setDisplayBanModal(false)}>Klar</button>
                  </div>
                </div>
              </SidebarModal>
            </>}
          </div>
          {banError && <p className='error px-3 text-right'>Det gick inte att spärra kunden</p>} 
        </div>
        }
      </div>
      {sessionId && 
        <div>
          <Modal title='Kundvarv' show={!!sessionId} onClose={onModalClose}>
            <SessionInfo />
          </Modal>
        </div>
      }
    </>
  )
}
